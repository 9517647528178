import Axios from 'axios'
import config from '../config'
import { downloadCSV } from "ra-core"
import {ActivityResponse, toUrlDate} from '../types'
import { postCodeOf, premisesOf, streetAddressOf } from '../resources/responses/types'
import moment from 'moment'
import { sentenceCase } from 'sentence-case'
import jsonExport from 'jsonexport/dist'
import {DateTime} from "luxon";

const downloadAnswerSetReport = async (tenantId: string, includeOrganisation: boolean, startDate: DateTime, endDate: DateTime, haveSubTenant?: boolean, externalIdColumnName?: string, filterText?: string) => {

    let isSuccessful = true
	const accessToken = sessionStorage.getItem('access_token')

	try {

        const filterTextQuery = filterText ? `&filterText=${filterText}` : ``
        // TODO: Move all web calls to one place
		const response = await Axios({
			method: 'get',
			url:`${config.API_URL}/tenants/${tenantId}/answerSets?pagesize=1000&from=${toUrlDate(startDate)}&to=${toUrlDate(endDate.plus({days: 1}))}${filterTextQuery}`,
			headers: { Authorization: "Bearer " +  accessToken}
		})

        if (response.data) {

            const activity = response.data.data as ActivityResponse
            let answerCount = 0;

            let usingSessionData = false;

            const questions = activity.response.map(a => {
                
                let row = {
                    [`userName`]: a.userName,
                } as Record<string, any>;

                if(haveSubTenant) row[`tenantName`] = a.tenantName;

                if(externalIdColumnName) {
                    row[externalIdColumnName] = a.externalUserId;
                }

                if(includeOrganisation) {
                    row[`organisation`] = a.usersOrgTenantIds;
                }

                row[`premises`] = premisesOf(a?.location);
                row[`streetAddress`] = streetAddressOf(a?.location);
                row[`postcode`] = postCodeOf(a?.location);
                row[`timeStamp`] = moment(a.timeStamp).format(`HH:mm D MMM yyyy`);
                if(a.sessionDurationMillis) {
                    usingSessionData = true;
                    const sessionMinutes = Math.floor(a.sessionDurationMillis / 60000);
                    const hours = Math.floor(sessionMinutes/60);
                    const minutesRemainder = sessionMinutes % 60;
                    let sessionDurationString = "";
                    if(hours===0) {
                        sessionDurationString = `${minutesRemainder}mins`;
                    } else if (minutesRemainder===0) {
                        sessionDurationString = `${hours}hrs`;
                    } else {
                        sessionDurationString = `${hours}hrs ${minutesRemainder}mins`;
                    }
                    row['sessionDuration'] = sessionDurationString;
                    row['sessionUniqueId'] = a.sessionTrackingId;
                    row['sessionStartedTimestamp'] = moment(a.sessionStartedTimestamp).format(`HH:mm D MMM yyyy`);
                }
                row[`isFit`] = a.isFit ? `Y` : `N`;
                row[`identityIsVerified`] = a.isAtLeastPartiallyIdentified ? `Y` : `N`;
                row[`isSuccessful`] = (a.isFit && a.isAtLeastPartiallyIdentified) ? `Y` : `N`;
                row[`latitude`] = a.questionsAnswered?.[0]?.location?.latitude || `n/a`;
                row[`longitude`] = a.questionsAnswered?.[0]?.location?.longitude || `n/a`;
                row[`locationAccuracy`] = a.questionsAnswered?.[0]?.location?.accuracy || `n/a`;
                row[`questionSetType`] = a.questionSetTitle ? a.questionSetTitle : a.questionSetType ? `Check Out` : `Check In`;
                row[`questionsAnswered`] = a.questionsAnswered.length;

                a.questionsAnswered.map((answer, i) => {
        
                    const isFreeText = (answer.questionType === "Free text")
                                    || ((answer.questionType === "Optional free text") && answer.optionChosen.triggersFreeText)
                    const isTravelTime = (answer.questionType === "Travel time");
                    let travelTime = answer?.travelTime;
                    if(isTravelTime && travelTime) {
                        let [hour, minute] = travelTime.split("-");
                        if(!hour) hour = "1";
                        if(!minute) minute = "0";
                        if(minute?.length === 1) minute = minute.padStart(2, '0')
                        if(hour?.length === 1) hour = hour.padStart(2, '0')
                        travelTime = `${hour} hr ${minute} min`;
                    }
        
                    const answerText = isFreeText ? answer.freeText
                                        : isTravelTime ? travelTime
                                                     : answer.optionChosen && answer.optionChosen.optionText
        
                    row[`question.${i + 1}`] = answer.questionText;
                    row[`question.${i + 1}.response`] = answerText;

                    row[`question.${i + 1}.isCorrect`] = isFreeText ? "n/a"
                                    : (answer.optionChosen && answer.optionChosen.isCorrect) ? `Y`
                                                                    : `N`;
                    row[`question.${i + 1}.identityVerified`] = (answer.faceResult?.faceIsMatchConfidence ?? 0) > 80 ? `Y` : `N`;
                    row[`question.${i + 1}.link`] = answer.link;
                    row[`question.${i + 1}.linkWasFollowed`] = (answer.linkWasFollowed === true) ? 'Y'
                                                            : (answer.linkWasFollowed === false) ? 'N'
                                                                                                 : '';
                    answerCount = Math.max(i + 1, answerCount);
                    return true;
                })

                return row;
            })

            let headers = ["userName"];
            if(haveSubTenant) headers = headers.concat(["tenantName"]);
            if(externalIdColumnName) {
                headers = headers.concat([externalIdColumnName]);
            }

            if(includeOrganisation) {
                headers = headers.concat(["organisation"]);
            }
            headers = headers.concat(["timeStamp"]);
            if(usingSessionData) {
                headers = headers.concat(["sessionDuration", "sessionUniqueId", "sessionStartedTimestamp"]);
            }

            headers = headers.concat([
                    "isFit",
                    "identityIsVerified",
                    "isSuccessful",
                    "premises",
                    "streetAddress",
                    "postcode",
                    "latitude",
                    "longitude",
                    "locationAccuracy",
                    "questionSetType",
                    "questionsAnswered",
                ]);

        
            new Array(answerCount).fill(1).map((_, i) => {
                headers = headers.concat([
                    `question.${i + 1}`,
                    `question.${i + 1}.response`,
                    `question.${i + 1}.isCorrect`,
                    `question.${i + 1}.identityVerified`,
                    `question.${i + 1}.link`,
                    `question.${i + 1}.linkWasFollowed`,
                ]);
                return true;
            });

            const mapHeaders = (header: string) => sentenceCase(header);

            jsonExport(
                questions,
                {
                    headers,
                    mapHeaders,
                },
                (err: any, csv: string) => downloadCSV(csv, `activity-export-${+new Date()}`),
            );
        }
        else {
            isSuccessful = false
        }
	}
	catch (Error) {
        console.log(Error)
		isSuccessful = false
	}

    return isSuccessful
}

export default downloadAnswerSetReport
