import { Signal } from "../components/customGrid/CellWithSignal";
import { QuestionsAnswered } from "../types";


const getDisplayForAnswer = (a: QuestionsAnswered, isValveSensor: boolean = false) => {

    const isFreeText = (a.questionType === "Free text")
                    || ((a.questionType === "Optional free text") && a.optionChosen.triggersFreeText)
    const isTravelTime = (a.questionType === "Travel time");
    let travelTime = a.travelTime;
    if(isTravelTime && travelTime) {
        let [hour, minute] = travelTime.split("-");
        if(!hour) hour = "1";
        if(!minute) minute = "0";
        if(minute?.length === 1) minute = minute.padStart(2, '0')
        if(hour?.length === 1) hour = hour.padStart(2, '0')
        travelTime = `${hour} hr ${minute} min`;
    }

    const isBatteryLevel = (isValveSensor && (a.questionText === "Battery level"))
    const isRotation = isValveSensor && ((a.questionText === "Minimum rotation")
                                      || (a.questionText === "Maximum rotation")
                                      || (a.questionText === "Current rotation"))

    const answerText = isFreeText && isBatteryLevel ? a.freeText + "%"      // TODO: Remove this special case for Valve Sensor
                         : isFreeText && isRotation ? a.freeText + "°"      // TODO: Remove this special case for Valve Sensor
                                       : isFreeText ? a.freeText
                                            : isTravelTime ? travelTime!
                                                    : a.optionChosen && a.optionChosen.optionText

    const isCorrectOption = (a.optionChosen && a.optionChosen.isCorrect)

    const answerSignal: Signal = isFreeText ? "Amber"
                          : isCorrectOption ? "Green"
                            : isTravelTime ? "Green"
                                            : "Red"
    const answerSignalText = isFreeText ? "Report"
                      : isCorrectOption ? "Correct"
                        : isTravelTime ? "Correct"
                                        : "Incorrect"

    const idSignal: Signal = (a.faceResult && a.faceResult.faceIsMatchConfidence > 80) ? "Green" : "Red"
    const idText = (a.faceResult && a.faceResult.faceIsMatchConfidence > 80) ? "Id verified" : "Id not verified"

    return {
        questionText: a.questionText,
        answerSignal,
        answerSignalText,
        answerText,
        idSignal,
        idText,
    }
}

export default getDisplayForAnswer